import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useCallback } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import CompletedUpload from "./CompletedUpload";

function BrandingArea(props)
{
    // initial message
    const logoDropzoneInitialMessage = (<>Drop your new logo here (or click to select it)<br/>Please use a .png, .jpg, .gif, .svg or .bmp file 1MB or smaller</>); 

    const [overprintName, setOverprintName] = useState('');
    const [overprintColour, setOverprintColour] = useState('#2360b1');
    const [useWhichImage, setUseWhichImage] = useState('no');
    const [existingImageName, setExistingImageName] = useState('');
    const [existingImageUrl, setExistingImageUrl] = useState('');

    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessage2, setErrorMessage2] = useState('')
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false);

    const [loaded, setLoaded] = useState(false)

    // initial file upload state
    const [logoFiles, setLogoFiles] = useState([]);

    const [newImageInError, setNewImageInError] = useState(false);

    // onDrop handlers
    const logoOnDrop = useCallback(acceptedFiles => {
        doUpload(acceptedFiles, 'LOGO')
        setLogoFiles([...logoFiles, ...acceptedFiles])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoFiles]);

    // dropzone props
    const [logoDropzoneDisabled, setLogoDropzoneDisabled] = useState(false);
    const [logoDropzoneDetail, setLogoDropzoneDetail] = useState(logoDropzoneInitialMessage);
    const { getRootProps:getLogoRootProps, getInputProps:getLogoInputProps } = useDropzone({
        onDrop: logoOnDrop,
        disabled: logoDropzoneDisabled || useWhichImage !== 'new'
    })

    const uploadComplete = (axiosEvent, filename, originalFilename, which_one) => {
        if (which_one !== 'LOGO') {
          return;
        }
        setLogoFilename(filename);
        setLogoDropzoneDisabled(true);
        uploadLogoFollowup(filename, originalFilename);
    }

    const uploadLogoFollowup = (filename, originalFilename) => {
        // the endpoint was created when upload processes were only validating XML but now it validates other upload types too
        const url = process.env.REACT_APP_API_END_POINT + 'uploadxmlfollowup';
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                filename,
                which_one: 'LOGO',
            }),
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success'] === true) {
              setLogoDropzoneDetail(<CompletedUpload success={true} filename={originalFilename} resetUpload={resetUploads} />);
              setUseWhichImage('new');
              setNewImageInError(false);
            } else {
              setLogoDropzoneDetail(<CompletedUpload success={false} error={data['message']} filename={originalFilename} resetUpload={resetUploads}/>);
              setNewImageInError(true);
            } 
        });
        
      }

      const navigate = useNavigate()

    // filenames as put to server
    const [logoFilename, setLogoFilename] = useState("");

    // remove file handlers
    const removeLogoFiles = () => {
        setLogoFiles([]);
    }

    const resetUploads = () => {
        removeLogoFiles();
        setLogoDropzoneDetail(logoDropzoneInitialMessage);
        setLogoFilename('');
        setLogoDropzoneDisabled(false);
    }

    const doUpload = (files, which_one) => {  
        if (which_one !== 'LOGO') {
          return;
        }
    
        setLogoDropzoneDisabled(true);
    
        var extension = files[0]['name'].split('.').pop().toLowerCase();
    
        if (!(['jpg', 'jpeg', 'svg', 'bmp', 'png', 'gif'].includes(extension))) {
          // the file extension wasn't a good one
          setLogoDropzoneDetail(<CompletedUpload success={false} error="Unknown image file type, please try .jpg .png .svg .bmp or .gif" filename={files[0].name} resetUpload={resetUploads} />);
          return;
        }
        
        setLogoDropzoneDetail("Starting upload...");
        setLogoFilename(''); // should be empty already, but make sure - I use if this is set to anything or not to tell when the upload is completed or now when save button is pressed
        
        const axiosConfig = {
          onUploadProgress: function(progressEvent) {
            var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            var uploadMessage = percentComplete + "% complete"
            setLogoDropzoneDetail(uploadMessage);
          }
        }
    
        const url = process.env.REACT_APP_API_END_POINT + 'upload';
        fetch(url,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'extension': extension,
              'isLogo': true
            }),
            method: "POST",
            credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
              setLogoDropzoneDetail("Sending file...");
              axios.put(data['upload_to'], files[0], axiosConfig)
                .then(res => uploadComplete(res, data['filename'], files[0]['name'], which_one));
            } else {
              setLogoDropzoneDetail(data['error']);
            }
          });
        
      }

    const doSaveSettings = (event) => {
      event.preventDefault()
      if (overprintName.trim() === '') {
        setErrorMessage("You must specify a company name, you can use your own name if you are not a registered company.")
        setErrorMessage2("Please add a company name and then resave.")
        setShowModal(true)
        return;
      }
      if (useWhichImage === 'new' && logoFilename === '') {
        setErrorMessage("You asked to use a new logo but did not provide a new logo file or it is still uploading.")
        setErrorMessage2("Please provide the logo file or wait for it to finish uploading.")
        setShowModal(true);
        return;
      }

      const url = process.env.REACT_APP_API_END_POINT + 'userbrandings/';
      var image = '';
      if (useWhichImage === 'new') {
        if (newImageInError) {
          image = existingImageName
        } else {
          image = logoFilename;
        }
      } else if (useWhichImage === 'existing') {
        image = existingImageName;
      } else { // 'no'
        image = ''; // which it was anyway
      }

      var action
      if (props.brandingId === 'new') {
        action = 'new';
      } else {
        action = 'update';
      }

      var requestParams = {
        "action": action,
        "image": image,
        "company_name": overprintName,
        "overprint_colour": overprintColour,
      }

      if (action === 'update') {
        requestParams['id'] = props.brandingId
      }

      fetch(url,
        {
          headers: {
            'Accept': 'application/json',
          },
          method: "POST",
          credentials: "include",
          body: JSON.stringify(requestParams)
        })
        .then((resp) => resp.json())
        .then((data) => {
          goHome();
        }); 
    }

    const goHome = () => {
      navigate('/settings')
    }

    useEffect(() => {
      if (props.brandingId === 'new') {
        setLoaded(true)
      } else {
        // get the brandings and select this one...
    
          const branding_url = process.env.REACT_APP_API_END_POINT + 'userbrandings/'
          fetch(branding_url, 
            {
              headers: {
                'Accept': 'application/json',
              },
              method: "GET",
              credentials: "include"
            })
            .then((resp) => resp.json())
            .then((data) => {
              if (data['success']) {
                const branding = data['brandings'].find((branding) => branding.id === props.brandingId)
                if (branding) {
                  setOverprintName(branding['company_name'])
                  setOverprintColour(branding['colour'])
                  if (branding['image_key']) {
                    setUseWhichImage('existing')
                    setExistingImageName(branding['image_key'])
                    setExistingImageUrl(branding['image_url'])
                  }
                  setLoaded(true)
                } else {
                  navigate('/')
                }
              } else {
                navigate('/')
              }
            }
          );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        <div className="contentsAreaHeader">
            <div className="areaTitle">Branding</div>
            <div className="areaExplainer">Configure your branding details to add to report pages</div>
        </div>

        { !loaded ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> : <>

            <div className="form-check form-switch">
              <div className="row mt-2">
                <label htmlFor="overprintName" className="col-sm-2 col-form-label"><strong>Company name:</strong></label>
                <div className="col-sm-4">
                  <input id="overprintName" className="form-control" type="text" placeholder="Example Ltd" value={overprintName} onChange={e => setOverprintName(e.target.value)} />
                </div>
              </div>

              <div className="row mt-2">
                <label className="col-sm-2 col-form-label"><strong>Custom logo:</strong></label>
                <div className="col-sm-4">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="logoChoice" id="logoChoice3" value="no" checked={useWhichImage==='no'} onChange={e => setUseWhichImage(e.target.value)} />
                    <label className="form-check-label" htmlFor="logoChoice3">No logo</label>
                  </div>

                  { props.brandingId === 'new' ? null : <>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="logoChoice" id="logoChoice1" value="existing" disabled={existingImageName === ''} checked={useWhichImage==='existing'} onChange={e => setUseWhichImage(e.target.value)} />
                      <label className="form-check-label" htmlFor="logoChoice1">Existing logo</label>
                      { existingImageUrl === '' ? <></>: <div><img alt="Existing logo" style={{maxWidth: "150px", maxHeight: "150px"}} src={existingImageUrl} /></div> }
                    </div>
                  </> }

                  <div className="form-check">
                    <input className="form-check-input open-content" type="radio" name="logoChoice" id="logoChoice2" value="new" checked={useWhichImage==='new'} onChange={e => setUseWhichImage(e.target.value)} />
                    <label className="form-check-label" htmlFor="logoChoice2">New logo</label>
                    <div className="col-sm-4 content-open fade-new">
                      <div {...getLogoRootProps({className: "dropzone"})}>
                        <input {...getLogoInputProps()} />
                        { logoDropzoneDetail }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <label htmlFor="overprintColour" className="col-sm-2 col-form-label"><strong>Brand colour:</strong></label>
                <div className="col-sm-4">
                  <input id="overprintColour" className="form-control" type="color" style={{width: "200px", height: "50px"}} value={overprintColour} onChange={e => setOverprintColour(e.target.value)} />
                </div>
              </div>

              <div className="form-group">
                <button type="submit" onClick={doSaveSettings} className="btn btn-primary btn-sm">Save</button>
                &nbsp;
                <button type="button" onClick={goHome} className="btn btn-secondary btn-sm">Cancel</button>
              </div>

            </div>

            </> }

            <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
            <Modal
              show={showModal}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Please check settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{errorMessage}</p>
                <p>{errorMessage2}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

        </>
    )
}

export default BrandingArea;