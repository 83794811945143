import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';

function NavBar()
{
    const currentOrigin = window.location.origin;
    const wwwOrigin = currentOrigin.replace('app', 'www')
    return (
        <div className="navBarContainer">
            <div className="navBarLogo"><a href={wwwOrigin}><img src="epims_logo.png" alt="epIMS Logo" /></a></div>
            <div className="navBarOptions">
                <div className="navBarOption"><a href={wwwOrigin} >Landlords</a></div>
                <div className="navBarOption"><a href={wwwOrigin + '/my-free-report/?utm_source=homepage&utm_campaign=homeowner'}>Homeowners</a></div>
                <div className="navBarOption"><a href={wwwOrigin + '/about.html'}>About&nbsp;Us</a></div>
                <div className="navBarOption"><a href={wwwOrigin + '/contact.html'}>Contact&nbsp;Us</a></div>
                <div className="navBarOption"><a href="https://www.linkedin.com/company/energy-performance-advice/posts/?feedView=all"><FontAwesomeIcon icon={faLinkedin} size="1x" /></a></div>
                <div className="navBarOption"><a href="https://www.facebook.com/profile.php?id=61560204003095"><FontAwesomeIcon icon={faFacebook} size="1x" /></a></div>
                <div className="navBarOption"><a href="https://www.instagram.com/epims5/"><FontAwesomeIcon icon={faInstagram} size="1x" /></a></div>
            </div>
        </div>
    )
}

export default NavBar;