import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import "../SharedReportInfo.css";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import PaymentForm from "../PaymentForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function SharedReportPrePayment(props) {

  const routeParams = useParams()
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  const [piId, setPiId] = useState(null)
  const [costCurrency, setCostCurrency] = useState(null)
  const [costAmount, setCostAmount] = useState(null)
  const [fullPriceAmount, setFullPriceAmount] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(null)
  const [discountCode, setDiscountCode] = useState(null)

  useEffect(() => {
    const url = process.env.REACT_APP_API_END_POINT + 'sharedreportprepayment';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        "report_path": routeParams.id + '/' + routeParams.slug,
        'stage': 'load'
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        // bad things happened
      } else {
        setStripePromise(loadStripe(data['pk']))
        setClientSecret(data['pi_secret'])
        setPiId(data['pi_id'])
        setCostAmount(data['cost_amount'])
        setCostCurrency(data['cost_currency'])
        setFullPriceAmount(data['full_price_amount'])
        setDiscountAmount(data['discount_amount'])
        setDiscountCode(data['discount_code'])
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="col-12 col-md-8">

      { (stripePromise && clientSecret) ? 
        <>
          <Elements stripe={stripePromise} options={{ clientSecret, appearance: {
            theme: "stripe",
            variables: {
              // this overrides all fonts within the stripe elements, importantly putting Segoe UI to the end as it is actually broken in input fields
              fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Segoe UI"',
            },
            rules: {
              '.Label': {
                fontWeight: 700,
                // this sets the font family back to what appears to be the stripe defaults for labels, everything was previously overridden
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
                color: '#000000',
                paddingTop: '8px'
              },
            }
          } }}>
            <PaymentForm reportData={props.reportData} {...{piId, costCurrency, costAmount, fullPriceAmount, discountAmount, discountCode}} />
          </Elements>
          <div style={{height: "100px"}}></div>
        </>
      : null }

      { !(stripePromise && clientSecret) ?
        <div><FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "100px", width: "100px" }} /></div>
      : null }

    </div>
  );
}

export default SharedReportPrePayment;