import React from "react";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDropzone } from 'react-dropzone';
import CompletedUpload from './CompletedUpload';
import { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function UploadXmlForm({generatePreliminaryReport, generatePremiumReport, userAccreditationScheme})
{
    const [reportGenerationInitialed, setReportGenerationInitiated] = useState(false);
    const [generateReportButtonsDisabled, setGenerateReportButtonsDisabled] = useState(true);
    const [manualCouncilTaxBandActive, setManualCouncilTaxBandActive] = useState(false);
    const [manualCTaxBand, setManualCTaxBand] = useState("A");
    const [commissioner, setCommissioner] = useState('landlord');

    // if the dropzone is disabled (becomes disabled once upload autostarts and stays that way)
    const [xmlADisabled, setXmlADisabled] = useState(false);
    const [xmlBDisabled, setXmlBDisabled] = useState(false);

    // initial file upload states...
    const [xmlAFiles, setXMLAFiles] = useState([]);
    const [xmlBFiles, setXMLBFiles] = useState([]);

    // If each individual upload is complete or not
    const [uploadAComplete, setUploadAComplete] = useState(false);
    const [uploadBComplete, setUploadBComplete] = useState(false);

    // initial file upload messages...
    const xmlAInitialMessage = (<strong>Drop the LIG XML file in this box (or click to select it)</strong>);
    const xmlBInitialMessage = (<strong>Drop the RdSAP XML file in this box (or click to select it)</strong>);

    // display content of the drop zone
    const [xmlADetail, setXmlADetail] = useState(xmlAInitialMessage);
    const [xmlBDetail, setXmlBDetail] = useState(xmlBInitialMessage);

    // filenames as they were named on the users machine, may not be needed but there is a chance some ID exists only in the name
    const [originalAFilename, setOriginalAFilename] = useState(null);
    const [originalBFilename, setOriginalBFilename] = useState(null);

    // filenames as they are put to the server
    const [xmlAFilename, setXmlAFilename] = useState(null);
    const [xmlBFilename, setXmlBFilename] = useState(null);

    const [cTaxPropertyAddress, setCTaxPropertyAddress] = useState("");

    const [postcodePropertyDetails, setPostcodePropertyDetails] = useState([]);
    const [postcodeCTaxBandDetails, setPostcodeCTaxBandDetails] = useState([]);

    const [showCreditMessage, setShowCreditMessage] = useState(false);
    const [modalButtonsDisabled, setModalButtonsDisabled] = useState(false);
    const [premiumReportCredits, setPremiumReportCredits] = useState(0);

    const [brandings, setBrandings] = useState([])
    const [selectedBranding, setSelectedBranding] = useState('epIMS')

    // remove file handlers
    const removeXmlAFiles = () => {
        setXMLAFiles([]);
    }
    const removeXmlBFiles = () => {
        setXMLBFiles([]);
    }

    // onDrop handlers
    const xmlAOnDrop = useCallback(acceptedFiles => {
        startUpload(acceptedFiles, 'A');
        setXMLAFiles([...xmlAFiles, ...acceptedFiles]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xmlAFiles]);
    const xmlBOnDrop = useCallback(acceptedFiles => {
        startUpload(acceptedFiles, 'B');
        setXMLBFiles([...xmlBFiles, ...acceptedFiles]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xmlBFiles]);

    // dropzone props
    const { getRootProps:getRootPropsXmlA, getInputProps:getInputPropsXmlA } = useDropzone({
        onDrop: xmlAOnDrop,
        disabled: xmlADisabled
    });
    const { getRootProps:getRootPropsXmlB, getInputProps:getInputPropsXmlB } = useDropzone({
        onDrop: xmlBOnDrop,
        disabled: xmlBDisabled
    });

    const resetUpload = (which, event) => {
        if (which === 'A') {
            removeXmlAFiles();
            setUploadAComplete(false);
            setXmlADisabled(false);    
            setXmlADetail(xmlAInitialMessage);
            setXmlAFilename(null);
            setOriginalAFilename(null);

            // extra bits to clear the property / council tax selection
            setCTaxPropertyAddress("");
            setPostcodePropertyDetails([]);
            setPostcodeCTaxBandDetails([]);
        } else if (which === 'B') {
            removeXmlBFiles();
            setUploadBComplete(false);
            setXmlBDisabled(false);    
            setXmlBDetail(xmlBInitialMessage);
            setXmlBFilename(null);
            setOriginalBFilename(null);
        }

        setReportGenerationInitiated(false);
    }

    const uploadXmlFollowup = (filename, which_one, originalFilename) => {
        const url = process.env.REACT_APP_API_END_POINT + 'uploadxmlfollowup';
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                filename,
                which_one
            }),
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success'] === true) {
                if (which_one === 'A') {
                    setUploadAComplete(true);
                    setXmlADetail(<CompletedUpload filename={originalFilename} resetUpload={() => resetUpload('A')}/>);
                } else if (which_one === 'B') {
                    setUploadBComplete(true);
                    setXmlBDetail(<CompletedUpload filename={originalFilename} resetUpload={() => resetUpload('B')}/>);
                }
                if ("ctax" in data) {
                    setPostcodePropertyDetails(data['ctax']['addresses']);
                    setPostcodeCTaxBandDetails(data['ctax']['bands']);
                }
            } else {
                if (which_one === 'A') {
                    setXmlADetail(<CompletedUpload success={false} error={data['message']} filename={originalFilename} resetUpload={() => resetUpload('A')}/>);
                } else if (which_one === 'B') {
                    setXmlBDetail(<CompletedUpload success={false} error={data['message']} filename={originalFilename} resetUpload={() => resetUpload('B')}/>);
                }
            } 
        });
    }

    const uploadComplete = (axiosEvent, filename, originalFilename, which_one) => {
        if (which_one === 'A') {
            setXmlADetail(<strong>Checking file...</strong>);
            setXmlAFilename(filename);
            setOriginalAFilename(originalFilename);
        } else if (which_one === 'B') {
            setXmlBDetail(<strong>Checking file...</strong>);
            setXmlBFilename(filename);
            setOriginalBFilename(originalFilename);
        }
        uploadXmlFollowup(filename, which_one, originalFilename);
    }

    const startUpload = async (files, which_one) => {
        if (which_one === 'A') {
            setXmlADetail(startSpinner);
            setXmlADisabled(true);
            setUploadAComplete(false);
        } else if (which_one === 'B') {
            setXmlBDetail(startSpinner);
            setXmlBDisabled(true);
            setUploadBComplete(false);
        }
        await uploadFiles(files, which_one);
    }

    const generatePreliminaryReportClick = () => {
        setReportGenerationInitiated(true)
        generatePreliminaryReport({xmlAFilename, xmlBFilename, originalAFilename, originalBFilename, commissioner, cTaxPropertyAddress, manualCouncilTaxBandActive, manualCTaxBand, selectedBranding});
    }

    const _generatePremiumReport = (useCredit) => {
        setModalButtonsDisabled(true);
        generatePremiumReport({xmlAFilename, xmlBFilename, originalAFilename, originalBFilename, commissioner, cTaxPropertyAddress, manualCouncilTaxBandActive, manualCTaxBand, selectedBranding, useCredit});
    }

    const generatePremiumReportClick = () => {
        var _credits = 0;
        setReportGenerationInitiated(true)
        const url = process.env.REACT_APP_API_END_POINT + 'premiumreportcredits';
        fetch(url, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: "GET",
            credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
                _credits = data['premium_report_credits'];
                setPremiumReportCredits(_credits);
                if (_credits > 0) {
                    setShowCreditMessage(true);
                } else {
                    _generatePremiumReport(false);
                }
            }
        });
    }

    const uploadFiles = (files, which_one) => {

        var extension;
        if (which_one === 'A') {
          extension = 'xml';
        } else if (which_one === 'B') {
          extension = 'xml';
        }
    
        return new Promise((resolve, reject) => {
          const axiosConfig = {
            onUploadProgress: function(progressEvent) {
              var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              var uploadMessage = percentComplete + "% complete"
              if (which_one === 'A') {
                setXmlADetail(uploadMessage);
              } else if (which_one === 'B') {
                setXmlBDetail(uploadMessage);
              }
            }
          }
    
          const url = process.env.REACT_APP_API_END_POINT + 'upload';
          fetch(url, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                'extension': extension
              }),
              method: "POST",
              credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
              if (which_one === 'A') {
                setXmlADetail(sendingSpinner)
              } else if (which_one === 'B') {
                setXmlBDetail(sendingSpinner)
              }
              // got a URL to upload to, in data['upload_to']. Now run the upload...
              axios.put(data['upload_to'], files[0], axiosConfig).then(
                res => {
                  uploadComplete(res, data['filename'], files[0]['name'], which_one)
                  resolve();
                }
              );
            } else {
              if (which_one === 'A') {
                setXmlADetail(data['error']);
              } else if (which_one === 'B') {
                setXmlBDetail(data['error']);
              }
              reject(data['error']);
            }
          })
        });
      }

    const requiresRdSAP = () => {
        return userAccreditationScheme === 'elmhurst';
    }

    useEffect(() => {
        if (reportGenerationInitialed) {
            setGenerateReportButtonsDisabled(true);
        } else {
            if (uploadAComplete && (!requiresRdSAP() || uploadBComplete) && (cTaxPropertyAddress !== "" || manualCouncilTaxBandActive)) {
                setGenerateReportButtonsDisabled(false);
            } else {
                setGenerateReportButtonsDisabled(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadAComplete, uploadBComplete, reportGenerationInitialed, cTaxPropertyAddress, manualCouncilTaxBandActive]);

    useEffect(() => {
        loadBrandings()
    }, [])


    const loadBrandings = () => {
    
        const branding_url = process.env.REACT_APP_API_END_POINT + 'userbrandings/'
        fetch(branding_url, 
          {
            headers: {
              'Accept': 'application/json',
            },
            method: "GET",
            credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
              const sortedBrandings = data['brandings'].sort((a, b) => 
                a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
              );
              sortedBrandings.unshift({
                id: 'epIMS',
                company_name: 'epIMS Default Branding',
              })
              setBrandings(sortedBrandings)
              setSelectedBranding(data['last_used'])
            }
          }
        );
      }

    const startSpinner = (
        <div className="my-5" style={{ textAlign: "center" }}>
          <FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
        </div>
    );

    const sendingSpinner = (
        <div className="my-5" style={{ textAlign: "center" }}>
            <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
        </div>
    );

    return (
        <>
            <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
                <Modal
                    show={showCreditMessage}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Use free credit?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You have {premiumReportCredits} Premium Report {premiumReportCredits === 1 ? 'credit' : 'credits'} remaining, do you want to apply a credit to this purchase?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={modalButtonsDisabled} variant="primary" onClick={() => _generatePremiumReport(true)}>Use 1 credit</Button>
                        <Button disabled={modalButtonsDisabled} variant="secondary" onClick={() => _generatePremiumReport(false)}>Pay with card</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div style={{display:"grid", gridTemplateColumns: "800px 560px"}}>
                <div style={{width:"525px"}}>
                    <p className="uploadStep"><span>Step 1:</span> Upload the LIG XML</p>
                    <div className="pb-2">
                        <div {...getRootPropsXmlA({className: "dropzone"})}>
                            <input id="ligUploader" {...getInputPropsXmlA()} />
                            { xmlADetail }
                        </div>
                    </div>
                    { requiresRdSAP() && <>
                        <p className="uploadStep"><span>Step 2:</span> Upload the RdSAP XML</p>
                        <div className="pb-2">
                            <div {...getRootPropsXmlB({className: "dropzone"})}>
                                <input id="rdsapUploader" {...getInputPropsXmlB()} />
                                { xmlBDetail }
                            </div>
                        </div>
                    </> }

                    <p className="uploadStep"><span>Step {requiresRdSAP() ? '3' : '2'}:</span> Was this report commissioned for a landlord or a homeowner?</p>
                    <div className="pb-3">
                        <select name="commissioner" style={{width: "250px"}} className="form-select" value={commissioner} onChange={e => setCommissioner(e.target.value)}>
                            <option value="landlord">A landlord</option>
                            <option value="homeowner">A homeowner</option>
                            <option value="other">Don&apos;t know / other</option>
                        </select>
                    </div>

                    <p className="uploadStep"><span>Step {requiresRdSAP() ? '4' : '3'}:</span> Select the correct property from the list below to identify its council tax band</p>

                    <div className="pb-3">
                        <select name="ctax" style={{width: "500px"}} className="form-select" value={cTaxPropertyAddress} onChange={e => setCTaxPropertyAddress((e.target.value))}>
                            <option key="empty" value="" disabled hidden>Select correct address...</option> 
                            {
                            postcodePropertyDetails.map((entry) => { return (
                                <option key={entry.address} value={entry.address}>
                                {entry.address} - (Band {entry.band} ~&pound;{(parseFloat(postcodeCTaxBandDetails['band_' + entry.band.toLowerCase()].replace(',', '')) / 12).toFixed(2)} / month)
                                </option>
                            )})
                            }  
                        </select>
                    </div>

                    <div style={{marginLeft: "30px"}}>

                        <div className="pb-3">
                            <div>If the property is not listed in the list above, or the given council tax band is wrong, please select it manually...</div>  
                        </div>

                        <div className="pb-3 form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="overrideCtax" checked={manualCouncilTaxBandActive} onChange={e => setManualCouncilTaxBandActive(e.target.checked)} />
                            <label className="form-check-label" htmlFor="overrideCtax"><strong>Manually set council tax band</strong></label>
                        </div>              

                        { manualCouncilTaxBandActive ? 
                            <div className="pb-3">
                                <select name="manualCtax" style={{width: "250px"}} className="form-select" value={manualCTaxBand} onChange={e => setManualCTaxBand(e.target.value)}>
                                    <option key="A" value="A">A</option> 
                                    <option key="B" value="B">B</option> 
                                    <option key="C" value="C">C</option> 
                                    <option key="D" value="D">D</option> 
                                    <option key="E" value="E">E</option> 
                                    <option key="F" value="F">F</option> 
                                    <option key="G" value="G">G</option> 
                                    <option key="H" value="H">H</option> 
                                </select>
                            </div>
                        : null }

                    </div>
                    <p className="uploadStep"><span>Step {requiresRdSAP() ? '5' : '4'}:</span> Select report branding</p>
                        <select name="branding" style={{width: "500px"}} className="form-select" value={selectedBranding} onChange={e => setSelectedBranding(e.target.value)}>
                            {
                                brandings.map((entry) => { return (
                                    <option key={entry.id} value={entry.id}>{entry.company_name}</option>
                                )})
                            }
                        </select>

                    <p className="uploadStep"><span>Step {requiresRdSAP() ? '6' : '5'}:</span> Choose report type</p>
                    <div>
                        <button type="submit" onClick={generatePreliminaryReportClick} className="btn btn-primary" disabled={generateReportButtonsDisabled}>Generate Preliminary Report</button>
                        <button style={{marginLeft: "10px"}} type="submit" onClick={generatePremiumReportClick} className="btn btn-primary" disabled={generateReportButtonsDisabled}>Generate Premium Report</button>
                    </div>
                </div>
                <div>
                    {userAccreditationScheme === 'elmhurst' && <>
                        <p><strong>Need assistance?</strong></p>
                        <p>We've created a helpful video that walks you through obtaining the XML files from Elmhurst and uploading them to create your report.</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ksh5eIZPO4Q?si=-lmDVkns-uHO3r6L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </>}
                </div>
            </div>
        </>
    )
}

export default UploadXmlForm;