import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function SettingsMainArea(props) {

const [settingsLoaded, setSettingsLoaded] = useState(false);

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');

  const [emailTemplate, setEmailTemplate] = useState('');
  const emailTemplateLengthLimit = 1000;

  const [brandings, setBrandings] = useState([]);

  const [showDeleteBrandingModal, setShowDeleteBrandingModal] = useState(false)
  const [brandingToDelete, setBrandingToDelete] = useState(null)
  const [brandingToDeleteName, setBrandingToDeleteName] = useState(null)

  const goHome = () => {
    navigate('/home');
  }

  const goNewBranding = () => {
    navigate('/branding/new')
  }

  const handleClose = () => setShowModal(false);

    const doSaveSettings = (event) => {
        event.preventDefault();
    
        if (emailTemplate.length > emailTemplateLengthLimit) {
          setErrorMessage("Email template must be " + emailTemplateLengthLimit + " characters long or shorter.")
          setErrorMessage2("Please provide a shorter version.");
          setShowModal(true);
          return;
        }
    
        const url = process.env.REACT_APP_API_END_POINT + 'usersettings/';
        
        fetch(url,
          {
            headers: {
              'Accept': 'application/json',
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
              "email_template": emailTemplate,
            })
          })
          .then((resp) => resp.json())
          .then((data) => {
            goHome();
          }); 
      }

    const loadSettings = () => {

        const settings_url = process.env.REACT_APP_API_END_POINT + 'usersettings/'
        
        fetch(settings_url, 
          {
            headers: {
              'Accept': 'application/json',
            },
            method: "GET",
            credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
              // depending on when the user was created the settings may not exist or even be set
              var settings = data['settings'];
            
              if ("email_template" in settings) {
                setEmailTemplate(settings['email_template']);
              }
    
              loadBrandings()
    
            } else {
              navigate('/');
            }
          }
        );
      }

    const loadBrandings = () => {
    
        const branding_url = process.env.REACT_APP_API_END_POINT + 'userbrandings/'
        fetch(branding_url, 
          {
            headers: {
              'Accept': 'application/json',
            },
            method: "GET",
            credentials: "include"
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
              const sortedBrandings = data['brandings'].sort((a, b) => 
                a.company_name.toLowerCase().localeCompare(b.company_name.toLowerCase())
              );
              setBrandings(sortedBrandings)
              setSettingsLoaded(true);
            } else {
              navigate('/');
            }
          }
        );
      }

      const deleteBranding = (id) => {
        setBrandingToDelete(id)
        setBrandingToDeleteName(brandings.find((branding) => branding.id === id).company_name)
        setShowDeleteBrandingModal(true)
      }

      const doDeleteBranding = (id) => {
        const branding_url = process.env.REACT_APP_API_END_POINT + 'userbrandings/'
        fetch(branding_url, 
          {
            headers: {
              'Accept': 'application/json',
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                id: brandingToDelete,
                action: 'delete'
            })
          })
          .then((resp) => resp.json())
          .then((data) => {
            if (data['success']) {
              setSettingsLoaded(false)
              setShowDeleteBrandingModal(false)
              loadBrandings()
            } else {
              navigate('/');
            }
          }
        );
      }

      useEffect(() => {
        if (!settingsLoaded) { // this is odd, why not did I not do it with useEffect(..., [])
          loadSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const applySettings = (event) => {
        event.preventDefault();
      }

    return (
        <>
        <div className="contentsAreaHeader">
            <div className="areaTitle">Branding</div>
            <div className="areaExplainer">Configure your branding details to add to report pages</div>
        </div>
        <div className="contents">
            <div style={{width: "1000px"}}>
            <div>
                { !settingsLoaded ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> : 
                <>
                <form onSubmit={applySettings}>

                    <div className="form-group">
                        <label className="mb-1 form-label"><strong>Branding templates:</strong></label>
                        <div style={{marginLeft: "20px"}}>
                            { brandings.length > 0 ? <>

                                <div style={{display: "grid", gridTemplateColumns: "max-content max-content max-content", marginBottom: "10px"}}>
                                    <div className="dataGridCellHeader" style={{paddingRight: "20px"}}>Name</div>
                                    <div className="dataGridCellHeader" style={{paddingRight: "20px"}}>Scheme</div>
                                    <div className="dataGridCellHeader" style={{paddingRight: "20px"}}>Actions</div>
                                    {
                                        brandings.map((branding) => {
                                        return (
                                            <>
                                                <div className="dataGridCell" style={{paddingRight: "20px"}}>{branding.company_name}</div>
                                                <div className="dataGridCell" style={{paddingTop: "4px", paddingRight: "10px"}}>
                                                    <div style={{display: "inline-block", marginRight: "10px", height: "21px", width: "100px", border: "1px solid black", backgroundColor: branding.colour}}>&nbsp;</div>
                                                    { branding.image_url !== false ? <>
                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip style={{opacity: "1"}}><img alt="" style={{maxHeight: "100px", maxWidth: "100px"}} src={branding.image_url} /></Tooltip>}>
                                                            <FontAwesomeIcon icon={solid("image")} style={{height: "22px", aspectRatio: "1", color: "#C0C0FF"}} />
                                                        </OverlayTrigger>
                                                    </> : null }
                                                </div>
                                                <div className="dataGridCell">
                                                  { branding.ro ? null : <>
                                                    <button className="btn btn-primary btn-sm" onClick={() => navigate('/branding/' + branding.id)}><FontAwesomeIcon icon={solid("pen-to-square")} style={{height: "16px", aspectRatio: "1", color: "white"}} /></button>
                                                    <button className="btn btn-primary btn-sm" style={{marginLeft: "6px"}} onClick={() => deleteBranding(branding.id)}><FontAwesomeIcon icon={solid("trash")} style={{height: "16px", aspectRatio: "1", color: "white"}} /></button>
                                                  </> }
                                                </div>
                                            </>
                                        )
                                        })
                                    }
                                </div>
                            </> : null }
                            <button onClick={goNewBranding} className="btn btn-primary btn-sm">Create new branding</button>
                        </div>
                    </div>

                    <div className="form-group" style={{marginTop: "20px"}}>
                        <label htmlFor="emailTemplateTextArea" className="mb-1 form-label"><strong>Email template:</strong></label>
                        <div style={{marginLeft: "20px"}}>
                            <div className="mb-3" style={{width: "500px"}}>Provide an email template for sending epIMS links to your clients, this will be displayed back to you on the share link screen with the text &quot;(LINK)&quot; replaced by the actual link.</div>
                            <textarea className="form-control" width="100%" rows="12" onChange={e => setEmailTemplate(e.target.value)} value={emailTemplate} />
                            <div>Characters used: {emailTemplate.length}/{emailTemplateLengthLimit}</div>
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" onClick={doSaveSettings} className="btn btn-primary btn-sm">Save</button>
                        &nbsp;
                        <button type="button" onClick={goHome} className="btn btn-secondary btn-sm">Cancel</button>
                    </div>
                </form>
                </> }
            </div>

            <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
                <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Please check settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{errorMessage}</p>
                    <p>{errorMessage2}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>OK</Button>
                </Modal.Footer>
                </Modal>
            </div>

            <div style={{display: "none"}} onClick={(e) => e.stopPropagation()}>
                <Modal
                show={showDeleteBrandingModal}
                onHide={() => setShowDeleteBrandingModal(false)}
                backdrop="static"
                keyboard={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete branding</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Confirm delete branding "{brandingToDeleteName}"</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={doDeleteBranding}>OK</Button>
                    <Button variant="secondary" onClick={() => setShowDeleteBrandingModal(false)}>Cancel</Button>
                </Modal.Footer>
                </Modal>
            </div>
            </div>
        </div>
        </>
    )
}

export default SettingsMainArea;