import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HousesFill } from 'react-bootstrap-icons';
import { HouseGearFill } from 'react-bootstrap-icons';
import { TelephoneFill } from 'react-bootstrap-icons';
import EpimsLogoNew from './components/EpimsLogoNew';

function RegisterPage() {

  const navigate = useNavigate();

  const [inviteCode, setInviteCode] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [scheme, setScheme] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [termsAgree, setTermsAgree] = useState(false);

  const [inviteExtraClasses, setInviteExtraClasses] = useState('');
  const [inviteError, setInviteError] = useState('');
  const [emailExtraClasses, setEmailExtraClasses] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameExtraClasses, setNameExtraClasses] = useState('');
  const [nameError, setNameError] = useState('');
  const [roleExtraClasses, setRoleExtraClasses] = useState('');
  const [roleError, setRoleError] = useState('');
  const [schemeExtraClasses, setSchemeExtraClasses] = useState('');
  const [schemeError, setSchemeError] = useState('');
  const [passwordExtraClasses, setPasswordExtraClasses] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmExtraClasses, setPasswordConfirmExtraClasses] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [agreeExtraClasses, setAgreeExtraClasses] = useState('');
  const [agreeError, setAgreeError] = useState('');

  const [error, setError] = useState("");

  const [registerCallInProgress, setRegisterCallInProgress] = useState(false);

   const handleInviteCodeChange = (event) => {
    setInviteCode(event.target.value)
    setInviteExtraClasses('');
    setInviteError('');
   } 

  const doRegister = (formEvent) => {
    
    setRegisterCallInProgress(true);
    setError("Working");

    const shortenedInviteCode = inviteCode.replaceAll('-', '').toLowerCase();
    const url = process.env.REACT_APP_API_END_POINT + 'user';
    fetch(url,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          inviteCode: shortenedInviteCode,
          email,
          name,
          role,
          scheme,
          password,
          passwordConfirm,
          termsAgree
        })
      })
      .then((resp) => resp.json())
      .then((data) => {

        setInviteExtraClasses('');
        setInviteError('');

        setEmailExtraClasses('');
        setEmailError('');

        setNameExtraClasses('');
        setNameError('');
        
        setRoleExtraClasses('');
        setRoleError('');

        setSchemeExtraClasses('');
        setSchemeError('');

        setPasswordExtraClasses('');
        setPasswordError('');

        setPasswordConfirmExtraClasses('');
        setPasswordConfirmError('');

        setAgreeExtraClasses('');
        setAgreeError('');

        if (data['success']) {
          navigate('/confirm', {
            state: {
              display_username: data['email'],
              username: data['username'],
              email: data['email']
            }
          });
        } else {
          setRegisterCallInProgress(false);
          if ('errors' in data) {
            data.errors.forEach(err => {
              if (err.key === 'inviteCode') {
                setInviteExtraClasses('is-invalid');
                setInviteError(err.error);
              }
              if (err.key === 'email') {
                setEmailExtraClasses('is-invalid');
                setEmailError(err.error);
              }
              if (err.key === 'name') {
                setNameExtraClasses('is-invalid');
                setNameError(err.error);
              }
              if (err.key === 'role') {
                setRoleExtraClasses('is-invalid');
                setRoleError(err.error);
              }
              if (err.key === 'scheme') {
                setSchemeExtraClasses('is-invalid');
                setSchemeError(err.error);
              }
              if (err.key === 'password') {
                setPasswordExtraClasses('is-invalid');
                setPasswordError(err.error);
              }
              if (err.key === 'passwordConfirm') {
                setPasswordConfirmExtraClasses('is-invalid');
                setPasswordConfirmError(err.error);
              }

              if (err.key === 'termsAgree') {
                setAgreeExtraClasses('is-invalid');
                setAgreeError(err.error);
              }
              setError('Please correct the issues above');
            });
          } else {
            setError(data['error']);
          }
        }
      });
    formEvent.preventDefault();
  }

    return (
      <div className="container pt-4">

      <div className="row d-flex justify-content-center align-items-center">
          <div className="col text-center pb-4">
            <EpimsLogoNew />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-5">
            <div className="row">
                <div className="col text-success">
                  <h1>Create your epIMS account</h1>
                </div>
            </div> 

            <div className="row">
            <div className='col pt-3'>
              <h6><HouseGearFill size={30} /> Access Your Energy Performance System</h6></div>
              <div><h6><TelephoneFill size={30} /> Telephone support to help you every step of the way</h6></div>
              <div><h6><HousesFill size={30} /> Fully bespoke reporting for all of your properties</h6></div>

            </div>
          </div>

        <div className="col">
          <div className="col-xl-8">
            <div className="card">
              <div className="card-header"><strong>Sign Up To epIMS</strong></div>
                <div className="card-body">
                  <form onSubmit={doRegister}>

                    <div className="form-group pb-2">
                        <label htmlFor="inviteCodeInput">Invite code</label>
                        <input id="inviteCodeInput" className={"form-control " + inviteExtraClasses} type="text" placeholder="xxxxx-xxxxx" value={inviteCode} onChange={(e) => handleInviteCodeChange(e)} aria-describedby="validationInvite" required/>
                        <div id="validationInvite" className="invalid-feedback">{inviteError}</div>
                        <small id="inviteCodeHelp" className="form-text text-muted">We are not accepting public signups at this time. If you have been given an invite code please enter it to continue.</small>
                    </div>        

                    <div className="form-group pb-2">
                      <label htmlFor="emailInput">Email</label>
                      <input id="emailInput" className={"form-control " + emailExtraClasses} type="email" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} aria-describedby='validationEmail' required />
                      <div id="validationEmail" className="invalid-feedback">{emailError}</div>
                    </div>

                    <div className="form-group pb-2">
                      <label htmlFor="nameInput">Full name</label>
                      <input id="nameInput" className={"form-control " + nameExtraClasses} type="text" placeholder="Joe Bloggs" value={name} onChange={(e) => setName(e.target.value)} aria-describedby='validationName' required />
                      <div id="validationName" className="invalid-feedback">{nameError}</div>
                    </div>

                    <div className="form-group pb-2">
                      <label htmlFor="roleInput">Role</label>
                      <select id="roleInput" className={"form-select " + roleExtraClasses} value={role} onChange={(e) => setRole(e.target.value)} aria-describedby='validationRole' required>
                        <option value="" disabled hidden>Please choose...</option>
                        <option value="landlord">Landlord / Home owner</option>
                        <option value="surveyor">Energy Assessor</option>
                        <option value="estate agent">Estate agent</option>
                        <option value="other">Other</option>
                      </select>
                      <div id="validationRole" className="invalid-feedback">{roleError}</div>
                      <small id="roleHelp" className="form-text text-muted">This helps us provide you with the relevant tools for your situation</small>
                    </div>

                    { role === 'surveyor' ? (
                      <div className="form-group pb-2">
                        <label htmlFor="schemeInput">Accreditation scheme</label>
                        <select id="schemeInput" className={"form-select " + schemeExtraClasses} value={scheme} onChange={(e) => setScheme(e.target.value)} aria-describedby='validationScheme'>
                          <option value="" disabled hidden>Please choose...</option>
                          <option value="elmhurst">Elmhurst</option>
                          <option value="stroma">Stroma</option>
                          <option value="qudos">Qudos</option>
                          <option value="ecmk">ECMK</option>
                          <option value="sterling">Sterling</option>
                          <option value="cibse">CIBSE</option>
                          <option value="other">Other</option>
                        </select>
                        <div id="validationScheme" className="invalid-feedback">{schemeError}</div>
                        <small id="schemeHelp" className="form-text text-muted">This will help us provide you with a more customised experience</small>
                      </div> ) : null }

                      <div className="form-group pb-2">
                        <label htmlFor="passwordInput">Password</label>
                        <input id="passwordInput" className={"form-control " + passwordExtraClasses} type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} aria-describedby='validationPassword'  minLength={8} required />
                        <div id="validationPassword" className="invalid-feedback">{passwordError}</div>
                        <small id="passwordHelp" className="form-text text-muted">Please choose a good password, 8 or more characters and include one number or symbol</small>
                      </div>

                      <div className="form-group pb-2">
                        <label htmlFor="passwordConfirmInput">Confirm password</label>
                        <input id="passwordConfirmInput" className={"form-control " + passwordConfirmExtraClasses} type="password" placeholder="Password" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} aria-describedby="validationConfirmPassword" required />
                        <div id="validationConfirmPassword" className="invalid-feedback">{passwordConfirmError}</div>
                        <small id="passwordConfirmHelp" className="form-text text-muted"></small>
                      </div>

                      <div className="form-check">
                        <label className="form-check-label" htmlFor="termsAgreeCheck">I agree to the <a href={"https://" + process.env.REACT_APP_WWW_BASE_DOMAIN + "/terms/EPA%20Terms.pdf"} target="_blank" rel="noreferrer">terms and conditions.</a></label>
                        <input id="termsAgreeCheck" className={"form-check-input " + agreeExtraClasses} type="checkbox" value="checked" checked={termsAgree} onChange={(e) => setTermsAgree(e.target.checked)} aria-describedby="validationTermsAgree" required />
                        <div id="validationTermsAgree" className="invalid-feedback">{agreeError}</div>
                      </div>

                      <div className="form-group text-end">
                            <span>{error}</span>&nbsp;<button disabled={registerCallInProgress} type="submit" className="btn btn-primary btn-sm">Register</button>
                      </div>
                  </form>
                </div>
                <div className="card-footer">
                  Already signed up? <Link to='/'>Login</Link>
                </div>
                </div>
              </div>
              <div style={{height: "100px"}}></div>
            </div>
          </div>
        </div>

    );
}

export default RegisterPage;