import { useParams } from "react-router-dom";
import BrandingArea from "./components/BrandingArea";
import SettingsMainArea from "./components/SettingsMainArea";

function Settings(props) {

  const routeParams = useParams();

  if ('brandingId' in routeParams) {
    return <BrandingArea {...props} brandingId={routeParams['brandingId']} />
  }

  return (
    <SettingsMainArea {...props} />
  );

}

export default Settings;
