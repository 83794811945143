import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReorderablePlan from "./components/ReorderablePlan";
import { Button } from "react-bootstrap";

var masterData = {}; // I'm told this might not be a good idea, but it doesn't matter that it isn't in state (its not rendered, just updated for later saving by other events), and does not want to be reset with each render. Also stores an object so updating it by state control is tricky (keys get updated in rapid succession before previous change has persisted)

function ProcessReportPage({reportId}) {

  const navigate = useNavigate();

  const [reportData, setReportData] = useState(null);

  const [plans, setPlans] = useState({});
  const [planIds, setPlanIds] = useState([]);
  const [customPlan, setCustomPlan] = useState(null);
  const [customPlanName, setCustomPlanName] = useState(null);
  const [allPossibleCustomMeasures, setAllPossibleCustomMeasures] = useState(null);

  const [epcStart, setEpcStart] = useState(0);
  const [eirStart, setEirStart] = useState(0);

  const [customPlanMeasuresLimit, setCustomPlanMeasuresLimit] = useState();

  const [customPlanDisabled, setCustomPlanDisabled] = useState(true); // does not do what you think it does, do not use. The whole plan management is done via masterData and is very hacky, the only place to call this is updateMasterData() and its only used to make sure the custom plan area get a re-render

  const submitPremiumReportData = () => {
    const url = process.env.REACT_APP_API_END_POINT + 'finalisepremiumreport/';
    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        "report_id": reportId,
        "plan_data": masterData,
        "customPlanName": customPlanName
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        alert("Something went wrong, try again or speak to support. " + data['error']);
      } else {
        navigate('/premium');
      }
    });
  }

  const updateMasterData = (planId, data, key) => {
    var _masterData = {
      ...masterData,
      [planId]: {...masterData[planId], [key]: data}
    }
    masterData = _masterData;
    setCustomPlanDisabled(!(masterData.custom?.planActive ?? false))
  }

  useEffect(() => {

    const url = process.env.REACT_APP_API_END_POINT + 'unprocessedpremiumreport/';

    fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        report_id: reportId
      })
    })
    .then((resp) => resp.json())
    .then((data) => {
      if (data['success'] === false) {
        setReportData(false);
      } else {
        setReportData(data['report_data']);
        var plans = {}; // object, will key it by plan ID and store plans in it
        var planIds = []; // array, will keep a separate list of plan IDs, it will be a bit easier to loop over it and get all the plans from 'plans', rather than doing plans.entries... etc (as not possible to .map() an object)
        data['report_data']['plans'].forEach(plan => {
          plans[plan.id] = plan;
          planIds.push(plan.id);
        });
        setPlans(plans);
        setPlanIds(planIds);
        setEpcStart(data['report_data']['epcStart']);
        setEirStart(data['report_data']['eirStart']);
        setAllPossibleCustomMeasures(data['report_data']['customPlanMeasures'])
        var customMeasures = [];
        data['report_data']['measures'].slice(0, data['report_data']['customPlanMeasuresLimit']).forEach((m) => {
          data['report_data']['customPlanMeasures'].forEach((cpm) => {
            if (m.code === cpm.code) {
              customMeasures.push(cpm)
            }
          })
        })
        setCustomPlan({
          collect: 'EPC',
          name: data['report_data']['customPlanDefaultName'],
          data: {
            measures: customMeasures
          }
        })
        setCustomPlanName(data['report_data']['customPlanDefaultName']);
        setCustomPlanMeasuresLimit(data['report_data']['customPlanMeasuresLimit']);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCodeInCustomPlan = (code) => {
    if(customPlan.data.measures.find((m) => {return m.code === code})) return true;
    return false;
  }

  const updateCustomMember = (code) => {
    if (customPlanDisabled) return;
    // bool, for if this code is already in the plan
    var isAlreadyInPlan = customPlan.data.measures.filter(x => x.code === code).length !== 0;
    // how many measures are already in the plan? We limit to some size that the BE gives
    var countInPlan = customPlan.data.measures.length;

    const _customPlan = {...customPlan}
    if (!isAlreadyInPlan) {
      if (countInPlan >= customPlanMeasuresLimit) {

      } else {
        _customPlan.data.measures.push(allPossibleCustomMeasures.find(cm => cm.code === code))
        setCustomPlan(_customPlan)
      }
    } else {
      _customPlan.data.measures = _customPlan.data.measures.filter(m => m.code !== code)
      setCustomPlan(_customPlan)
    }
  }

  if (reportData === null) {
    return ( <div className="my-5" style={{ textAlign: "center" }}>
              <div className="my-5">Loading</div>
              <div><FontAwesomeIcon className="spinner-slow" icon={solid("spinner")} style={{color: "#000000", height: "150px", width: "150px" }} /></div>
            </div>
          )
  } else if (reportData === false) {
    return ( <div>Error loading data</div> )
  } else {
    // probably should move this to a full component
    // unused options disable
    var unusedOptionsDisabled = customPlan.data.measures.length >= customPlanMeasuresLimit;
    return ( 
      <div>
        <div>
          <div className="pageSection">{reportData.name}</div>
        </div>
        <div className="pageSection">Measures in XML</div>
        <div style={{width: "600px"}}>
          <div className="row m-0 p-0">
            <div className="col mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>Measure</strong></div>
            <div className="col-3 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>EPC Points</strong></div>
            <div className="col-3 mx-0 dataCell dataCellHeader" style={{textAlign: "left"}}><strong>EIR Points</strong></div>
          </div>
          {
            reportData.measures.map((measure) => {
              return (
                <li key={"xmlImprovementsLi" + measure.code} className="p-0 m-0 list-group-item list-group-item-primary list-group-item-action" style={{border: "0px"}}>
                  <div className="row m-0 p-0">
                    <div className="col mx-0 dataCell" style={{textAlign: "left"}}>{measure.name}</div>
                    <div className="col-3 mx-0 dataCell" style={{textAlign: "left"}}>{measure.epcIncrease}</div>
                    <div className="col-3 mx-0 dataCell" style={{textAlign: "left"}}>{measure.eirIncrease}</div>
                  </div>
                </li>
              )
            })
          }
        </div>
        <div>
          <div className="pageSection">Plans</div>
          { planIds.map((planId) => {
              const plan = plans[planId];
              return <ReorderablePlan key={"planContainer" + planId} updateMasterData={(data, key) => updateMasterData(planId, data, key)} {...{planId, plan, epcStart, eirStart}} />
          }) }
        </div>
        <div className="pageSection">Custom Plan</div>
        <div style={{display: "grid", gridTemplateColumns: "400px 1fr"}}>          
          <ReorderablePlan key="planContainerCustom" updateMasterData={(data, key) => updateMasterData("custom", data, key)} {...{planId: "custom", plan: customPlan, epcStart, eirStart}} />
          <div>
            <p style={{fontSize: "20px", marginBottom: "8px"}}>Choose up to {customPlanMeasuresLimit} measures to include in your custom plan.</p>
            <div style={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
              {allPossibleCustomMeasures.map((m, n) => {
                return (
                  <button className="customPlanPicker" onClick={e => updateCustomMember(m.code)}>
                    <div style={{zIndex: 100, display: (unusedOptionsDisabled && !isCodeInCustomPlan(m.code)) || customPlanDisabled ? "initial" : "none"}} className="disabled">
                    </div>
                    <input style={{display: "inline", minHeight: "32px", minWidth: "32px"}} id={"measureCheck" + n} type="checkbox" checked={isCodeInCustomPlan(m.code)} onChange={() => {}} />
                    <p style={{display: "inline", margin: "0 0 0 10px"}}>
                      <strong>{m.name}</strong>
                    </p>
                  </button>
                )
              })}
            </div>
          </div>  
        </div>
        <Button onClick={submitPremiumReportData}>Finalise Premium Report</Button>
      </div>
     )
  }
}

export default ProcessReportPage;